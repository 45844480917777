.payment-form-root {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 46px 20px;

  &__title {
    font-weight: 700;
    font-size: 34px;
    line-height: 46px;
    margin: 0 0 30px;
  }

  &__time {
    padding: 16px 11px;
    background-color: #fff;
    display: flex;
    align-items: center;
    width: 47.5%;
    border-radius: 20px;

    p {
      font-size: 14px;
      line-height: 1.3;
      font-weight: 700;
      margin: 0;

      &:last-child {
        color: #9F9FC9;
      }
    }
  }

  &__icon {
    width: 30px;
    height: 30px;
    margin-right: 11px;
  }

  &__services {
    background-color: #fff;
    margin-top: 10px;
    border-radius: 20px;
    padding: 0 11px;
  }

  &__total-amount {
    display: flex;
    align-items: center;
    background-color: #fff;
    border-radius: 20px;
    padding: 16px 11px;
    margin: 10px 0 30px;

    p {
      font-size: 14px;
      font-weight: 700;
      margin: 0 11px 0 0;

      &:last-child {
        color: #9F9FC9;
        font-size: 14px;
        font-weight: 700;
        text-align: right;
        margin: 0;
      }
    }
  }
}

@media screen and (min-width: 570px) {
  .payment-form-root {
    padding: 46px 33px;
  }
}