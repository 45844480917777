$main-color: #183b89;
$secondary-color: #097557;
$primarly: #92d0e4;

.title {
  font-size: 30px;
  font-weight: 700;
  line-height: 46px;
  margin: 0 0 20px;
}
