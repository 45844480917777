.online-record-modal {
  font-family: Montserrat;
  display: flex;
  align-items: center;
  justify-content: center;
  &__paper {
    width: 500px;
    height: 500px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-evenly;
    border-radius: 20px;
    opacity: 1;
    padding: 30px 80px;
    background-color: white;
    transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    // gap: 30px;
    &:focus {
      outline: none !important;
    }
  }

  &__title {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    margin: 0;
    width: 90%;
  }

  &__subtitle {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    margin: 0;
    width: 90%;
  }

  &__btn {
    width: 100%;
    padding: 13px 0;
    font-size: 16px;
    font-weight: 600;
    border-radius: 12px;
    background-color: $main-color;
    color: #fff;
  }


}

@media (max-width: 530px) {
  .online-record-modal {
    &__paper {
      width: 90%;
      justify-content: inherit;
      gap: 20px;
      padding: 30px;
    }
  }
}