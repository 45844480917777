@import '../OnlineRecord/_variables.scss';

.doctors {
  &__links {
    &-block {
      max-width: 1000px;
      width: 100%;
      margin: 100px auto;
      border-radius: 15px;
      margin-top: 50px;
      font-size: 26px;
      text-align: center;
    }

    &-title {
      font-weight: 700;
      text-decoration: underline;
    }

    &-link {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-top: 10px;
      border: 5px solid #e1e1e1;
      border-radius: 15px;
      padding: 0 20px;
      font-weight: 300;
      &-href {
        color: $main-color;
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
}